<template>
  <div class="resume">
    <Header :isHeader="true" :back="true" :step="currentStepIndex" />
    <Wrapper :ismain="true">
      <template v-slot:sidebar>
        <Sidebar />
      </template>
      <Title titleStyle="title" :text="translateString('resume')" />
      <div class="card card-overflow elm-mobile">
        <div>
          <img class="car-image" :src="carImage" alt="car image"/>
        </div>
        <p>
          <span class="big-text">{{ carPlate }}</span
          ><br />
          <span class="text">{{ carName }}</span>
        </p>
      </div>
      <div class="resume-details">
        <Card>
          <template v-slot:header>
            <p class="text-highlight">{{ translateString("services") }}</p>
          </template>
          <template>
            <ListItemComplex
              v-for="(service, index) in servicesSelected"
              :key="index"
            >
              <template v-slot:label>
                <p class="sm-text">{{ service["group"].name }}</p>
                <p
                  v-for="(s, i) in service['services']"
                  :key="i"
                  class="sm-text disabled"
                >
                  {{ s.name }}
                </p>
              </template>
              <template v-slot:value>
                <div class="row">
                  <div
                    v-if="service.group.price == service.group.oldPrice"
                    class="price-container"
                  >
                    <p class="text price">
                      {{ formatPrice(service.group.price, 2, "€") }}
                    </p>
                  </div>
                  <div v-else class="price-container">
                    <p class="text old-price">
                      {{ formatPrice(service.group.oldPrice, 2, "€") }}
                    </p>
                    <p class="text price">
                      {{ formatPrice(service.group.price, 2, "€") }}
                    </p>
                  </div>
                  <EditIcon :isButton="true" @action="goTo('Services')" />
                </div>
              </template>
            </ListItemComplex>
            <ListItemComplex v-if="Object.keys(mobility).length > 0">
              <template v-slot:label>
                <p class="sm-text">{{ translateString("mobility") }}</p>
                <p
                  class="sm-text disabled"
                  v-for="(service, index) in mobility"
                  :key="index"
                >
                  {{ service.name }}
                </p>
              </template>
              <template v-slot:value>
                <div class="row">
                  <div
                    v-if="mobilityPriceTotal == mobilityPriceTotal"
                    class="price-container"
                  >
                    <p class="text price">
                      {{ formatPrice(mobilityPriceTotal, 2, "€") }}
                    </p>
                  </div>
                  <div v-else class="price-container">
                    <p class="text old-price">
                      {{ formatPrice(mobilityPriceOldTotal, 2, "€") }}
                    </p>
                    <p class="text price">
                      {{ formatPrice(mobilityPriceTotal, 2, "€") }}
                    </p>
                  </div>
                  <EditIcon
                    :isButton="true"
                    @action="goTo('MobilityServices')"
                  />
                </div>
              </template>
            </ListItemComplex>
            <ListItemComplex v-if="wash.serviceName != ''">
              <template v-slot:label>
                <p class="sm-text">{{ translateString("wash") }}</p>
                <p
                  class="sm-text disabled"
                  v-for="(service, index) in wash"
                  :key="index"
                >
                  {{ service.name }}
                </p>
              </template>
              <template v-slot:value>
                <div class="row">
                  <div
                    v-if="washPriceTotal == washPriceTotal"
                    class="price-container"
                  >
                    <p class="text price">
                      {{ formatPrice(washPriceTotal, 2, "€") }}
                    </p>
                  </div>
                  <div v-else class="price-container">
                    <p class="text old-price">
                      {{ formatPrice(washPriceOldTotal, 2, "€") }}
                    </p>
                    <p class="text price">
                      {{ formatPrice(washPriceTotal, 2, "€") }}
                    </p>
                  </div>
                  <EditIcon :isButton="true" @action="goTo('WashServices')" />
                </div>
              </template>
            </ListItemComplex>
          </template>
          <ListItemComplex>
            <template v-slot:label>
              <p class="text-highlight">Total</p>
            </template>
            <template v-slot:value>
              <p class="preco-marcacao text-highlight">
                {{ formatPrice(servicesTotal, 2, "€") }}
              </p>
            </template>
          </ListItemComplex>
          <p v-if="totalDisclaimer != ''" class="disclaimer">
            {{ totalDisclaimer }}
          </p>
          <p class="xs-text-italic" v-if="maintenanceDisclaimer">
            {{ translateString("maintencanceDisclaimer") }}
          </p>
          <br/>
          <p class="text-highlight">Comentários</p>
          <TextArea
            v-if="markingServiceSelected && markingServiceSelected.observations"
            id="comments"
            :placeholder="translateString('obs')"
            @updateInput="updateComments"
            type="comments"
            :maxLength="500"
            v-model="markingServiceSelected.observations"
          >
          </TextArea>

          <TextArea
            v-else
            id="comments"
            :placeholder="translateString('obs')"
            @updateInput="updateComments"
            type="comments"
            :maxLength="500"
          >
          </TextArea>
        </Card>

        <Card>
          <template v-slot:header>
            <p class="text-highlight">{{ translateString("dealer") }}</p>
            <EditIcon :isButton="true" @action="goTo('DealerSelection')" />
          </template>
          <ListItemComplex>
            <template v-slot:label>
              <p class="sm-text">{{ dealerSelected.dealerName }}</p>
              <p class="sm-text disabled">
                {{ dealerSelected.dealerAddress }}<br />
                {{ dealerSelected.dealerPostalCode }}
              </p>
            </template>
          </ListItemComplex>
        </Card>
        <Card>
          <template v-slot:header>
            <p class="text-highlight">{{ translateString("dateHour") }}</p>
            <EditIcon :isButton="true" @action="goTo('Schedule')" />
          </template>
          <ListItemComplex>
            <template v-slot:label>
              <p class="sm-text">
                {{ date }} às {{ availabilitySelected.hour }}
              </p>
            </template>
          </ListItemComplex>
        </Card>
        <Card>
          <template v-slot:header>
            <p class="text-highlight">{{ translateString("personalData") }}</p>
            <EditIcon :isButton="true" @action="goTo('ContactInfo')" />
          </template>
          <ListItemComplex>
            <template v-slot:label>
              <p class="sm-text">{{ translateString("name") }}</p>
            </template>
            <template v-slot:value>
              <p class="text">{{ contactInfo.name }}</p>
            </template>
          </ListItemComplex>
          <ListItemComplex>
            <template v-slot:label>
              <p class="sm-text">{{ translateString("email") }}</p>
            </template>
            <template v-slot:value>
              <p class="text">{{ contactInfo.email }}</p>
            </template>
          </ListItemComplex>
          <ListItemComplex>
            <template v-slot:label>
              <p class="sm-text">{{ translateString("phone") }}</p>
            </template>
            <template v-slot:value>
              <p class="text">{{ contactInfo.phone }}</p>
            </template>
          </ListItemComplex>
        </Card>
        </div>

      <template v-slot:footer>
        <Footer>
          <Button
            id="makeAppointment"
            class="btn-primary align-btn-makeAppointment"
            :label="translateString('finish')"
            @action="next('makeAppointment')"
          />
        </Footer>
      </template>
    </Wrapper>
    <Modal :msg="callError" :isActive="callError != ''" />
  </div>
</template>

<script>
import { country } from "@/services/country";

import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/pt";

import Button from "@/components/Buttons/Button.vue";
import Card from "@/components/Card/Card.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import ListItemComplex from "@/components/List/ListItemComplex.vue";
import Modal from "@/components/Modal/Modal.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Title from "@/components/Title/Title.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";
import TextArea from "@/components/Inputs/TextArea.vue";

import EditIcon from "@/assets/icons/EditIcon.vue";

import { mapGetters, mapState } from "vuex";

import { formatPrice, translateString } from "@/functions/functions.js";

export default {
  name: "Resume",
  components: {
    Button,
    Card,
    EditIcon,
    Footer,
    Header,
    ListItemComplex,
    Modal,
    Sidebar,
    Title,
    Wrapper,
    TextArea,
  },
  data() {
    return {
      servicesSelected: this.$store.state.servicesSelectedCompletedList,
      servicesTotal: this.$store.state.servicesTotal,
      dealerSelected: this.$store.state.dealerSelected,
      carImage: this.$store.state.carImage
        ? this.$store.state.carImage
        : require("@/assets/car_placeholder.png"),
      carPlate: this.$store.state.carPlate,
      carName: this.$store.state.carName
        ? this.$store.state.carName
        : this.$store.state.carNF.brand + " " + this.$store.state.carNF.model,
      availabilitySelected: this.$store.state.availabilitySelected,
      mobility: this.$store.state.mobilityServiceSelected,
      mobilityPriceOldTotal: this.$store.state.mobilityServicesOldTotal,
      mobilityPriceTotal: this.$store.state.mobilityServicesTotal,
      wash: this.$store.state.washServiceSelected,
      washPriceOldTotal: this.$store.state.washServicesOldTotal,
      washPriceTotal: this.$store.state.washServicesTotal,
      contactInfo: this.$store.state.contactInfo,

      date: "",
      tiresDisclaimer: false,
      maintenanceDisclaimer: false,
      obs: "",
    };
  },
  computed: {
    finalError() {
      return this.$store.state.finalError;
    },
    totalDisclaimer() {
      let disclaimer = "";

      if (this.$store.state.totalDisclaimer.length > 0) {
        disclaimer += "* ";
        this.$store.state.totalDisclaimer.forEach((el) => {
          disclaimer += el + ", ";
        });

        disclaimer = disclaimer.slice(0, -2);

        disclaimer += " não inclduído no preço";
      }

      return disclaimer;
    },
    scheduleReady() {
      return this.$store.state.scheduleReady;
    },
    callError() {
      return this.$store.state.callError;
    },
    ...mapGetters(["getCurrentStepIndex"]),

    currentStepIndex() {
      return this.$store.state.currentStepIndex;
    },
    ...mapState(["markingServiceSelected"]),
  },
  created() {
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
    if (this.$store.state.carPlate == "") {
      this.$router.replace({ name: "Homepage" });
    }

    this.date = dayjs(this.availabilitySelected.day)
      .locale(country)
      .format("dddd, DD MMMM YYYY");
    this.checkDisclaimers();
  },
  methods: {
    translateString,
    formatPrice,
    checkDisclaimers() {
      //Manutenção
      this.servicesSelected.forEach((service) => {
        service["services"].forEach((el) => {
          if (el.external) {
            this.maintenanceDisclaimer = true;
          }
        });
      });
    },
    goTo(page) {
      if (page === 'MobilityServices') {
        this.$store.dispatch('clearMobilityServices');
      } else if(page === "Services"){
        this.$store.dispatch('clearMobilityServices');
        this.$store.dispatch('clearWashServices');
      } else if (page === 'WashServices') {
        this.$store.dispatch('clearWashServices');
      }
      this.$router.push({ name: page });
    },
    updateComments(value) {
      this.obs = value;
    },
    next(id) {
      if (!this.scheduleReady) {

        this.$store.dispatch("setCurrentStepIndex", this.currentStepIndex + 1);
        this.$store.dispatch("selectOption", {
          selectedValue: "obs",
          value: this.obs,
        });
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: id,
        });
        this.$store.dispatch("makeAppointment");
      }
    },
  },
  watch: {
    scheduleReady() {
      if (this.scheduleReady) {
        this.$router.push({ name: "Confirmation" });
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: false,
        });
      }
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: $whiteColor;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba($blackColor, 0.15);
  padding: 1rem 1.5rem;
}

.card,
.card-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
}

.car-image {
  margin-top: -2rem;
  max-width: 200px;
  mix-blend-mode: multiply;
  width: 60%;
}

.resume-details {
  @include respond-to-min("md") {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    grid-template-areas:
      "services dealers"
      "services schedule"
      "services contact";

    > :nth-child(1) {
      grid-area: services;
    }
    > :nth-child(2) {
      grid-area: dealers;
    }
    > :nth-child(3) {
      grid-area: schedule;
    }
    > :nth-child(4) {
      grid-area: contact;
    }

    > * {
      margin: 0;
    }
  }
}
</style>
